import bugsnagInit from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import getConfig from 'next/config'
import React, { ElementType } from 'react'

const { publicRuntimeConfig } = getConfig()

// NOTE: For folks trying to extract a common helper function: don't.
// process.env cannot be indexed via env[key] due to webpack black magic.
const getApiKey = (): string => {
  const key = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY
  if (!key) {
    throw new Error('NEXT_PUBLIC_BUGSNAG_API_KEY is not defined')
  }
  return key
}

const getEndpointNotify = (): string => {
  const endpoint = process.env.NEXT_PUBLIC_BUGSNAG_ENDPOINT_NOTIFY
  if (!endpoint) {
    throw new Error('NEXT_PUBLIC_BUGSNAG_ENDPOINT_NOTIFY is not defined')
  }
  return endpoint
}

const getEndpointSessions = (): string => {
  const endpoint = process.env.NEXT_PUBLIC_BUGSNAG_ENDPOINT_SESSIONS
  if (!endpoint) {
    throw new Error('NEXT_PUBLIC_BUGSNAG_ENDPOINT_SESSIONS is not defined')
  }
  return endpoint
}

const getAppVersion = (): string | undefined => {
  const version = publicRuntimeConfig.processEnv.NEXT_PUBLIC_APP_VERSION
  if (!version) {
    console.warn(
      'NEXT_PUBLIC_APP_VERSION is not defined, should only happen in local dev',
    )
    return undefined
  }
  return version
}

const isServerSide = typeof window === 'undefined'
const isLocalDev = process.env.NODE_ENV === 'development'

export const bugsnag =
  isServerSide || isLocalDev
    ? undefined
    : bugsnagInit({
        apiKey: getApiKey(),
        endpoints: {
          notify: getEndpointNotify(),
          sessions: getEndpointSessions(),
        },
        collectUserIp: false,
        appVersion: getAppVersion(),
        releaseStage: publicRuntimeConfig.processEnv.NEXT_PUBLIC_ENV,
      })

bugsnag?.use(bugsnagReact, React)

if (!isServerSide) {
  window.bugsnag = bugsnag
}

// no bugsnag for server side rendering or local development
export const BugsnagBoundary: ElementType | undefined =
  bugsnag?.getPlugin('react')
