const DEFAULT_FALLBACK_STATION = 'rtl'
const DEFAULT_FALLBACK_COUNTRY = 'de'

export interface BroadcastInfo {
  station?: string
  country?: string
}

export function useCurrentStationInfo(): { station: string; country: string } {
  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''
  const [
    ,
    station = DEFAULT_FALLBACK_STATION,
    country = DEFAULT_FALLBACK_COUNTRY,
  ] = pathname.match(/\/(.*?)\/(.{2}?)(?:\/|$)/) ?? []

  return {
    station,
    country,
  }
}
