import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  createContext,
  useContext,
} from 'react'
import { useRouter } from 'next/router'

const HistoryContext = createContext<{ previousRoute?: string }>({})

export function HistoryContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [previousRoute, setPreviousRoute] = useState<string>()
  const router = useRouter()

  const handleBeforeHistoryChange = useCallback(
    (url: string) => {
      const [nextUrl] = url?.split('?') || []

      if (nextUrl !== router.asPath) {
        setPreviousRoute(router.asPath)
      }
    },
    [router.asPath],
  )

  useEffect(() => {
    router.events.on('beforeHistoryChange', handleBeforeHistoryChange)

    return () => {
      router.events.off('beforeHistoryChange', handleBeforeHistoryChange)
    }
  }, [handleBeforeHistoryChange, router])

  return (
    <HistoryContext.Provider
      value={useMemo(() => ({ previousRoute }), [previousRoute])}
    >
      {children}
    </HistoryContext.Provider>
  )
}

export default function useRouteUrlHistory() {
  return useContext(HistoryContext)
}
