import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Tiresias', 'Helvetica Neue', 'Helvetica', 'Roboto', sans-serif;
}

body {
    margin: 0;
    width: 1280px;
    height: 720px;
    overflow: hidden;
}

#videodiv {
  position: absolute;
  z-index: 101;
  overflow: hidden;
  background: black;
  display: none;
  
  top: 0px;
  left: 0px;
  width: 1280px;
  height: 720px;
}

#video {
  position: absolute;
}
`

export default GlobalStyle
