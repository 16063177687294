const ActiveItemLight = '/playlist-video-light.png'
const backIcon = '/player/back.png'
const forwardIcon = '/player/forward.png'
const fullscreenIcon = '/player/fullscreen.png'
const loadingSpinner = '/spinner.gif'
const pauseIcon = '/player/pause.png'
const playIcon = '/player/play.png'
const rewindIcon = '/player/rewind.png'

const MAX_WIDTH = 1280
const CONTENT_WIDTH = 1120

export const LIGHT_THEME = 'LIGHT_THEME'
export const DARK_THEME = 'DARK_THEME'

const palette = {
  primary: [
    '#6b737a',
    '#000000',
    '#ffffff',
    '#313e45',
    '#bdbdbd',
    '#e0e0e0',
    '#eeeeee',
    '#ffffff',
  ],
  white: ['#fff', '#fff', '#eee'],
  grayscale: [
    '#212121',
    '#414141',
    '#616161',
    '#9e9e9e',
    '#bdbdbd',
    '#e0e0e0',
    '#eeeeee',
    '#ffffff',
  ],
}

const theme = {
  name: 'TV-Highlights Default',

  colors: {
    primary: '#000',
    primaryText: '#fff',
    secondary: '#fff',
    secondaryText: '#000',
    progressBarBackground: '#000',
    progressBarForeground: '#fff',
  },

  palette: palette,

  colorbuttons: {
    primary: {
      red: '#e60f44',
      green: '#309239',
      blue: '#106088',
      yellow: '#f8b101',
    },
  },

  fonts: {
    primary: 'Teresias, Helvetica Neue, Helvetica, Roboto, sans-serif',
  },

  fontSizes: {
    primary: '20px',
    secondary: '19px',
    small: '18px',
  },

  sizes: {
    maxWidth: MAX_WIDTH + 'px',
    maxHeight: '720px',
    contentWidth: CONTENT_WIDTH + 'px',
  },

  exclusionZoneSpacing: (MAX_WIDTH - CONTENT_WIDTH) / 2 + 'px',

  focusBorderRadius: '4px',

  zIndexVideoPlayer: 995,
  videoPlayerDimensions: {
    small: {
      height: 415,
      width: 741,
      top: 118,
      left: 449,
      headerHeight: 35,
    },
    fullscreen: {
      height: 720,
      width: 1280,
      top: 0,
      left: 0,
    },
  },
  playlistDimensions: {
    height: 515,
    headerHeight: 35,
  },
  assets: {
    loadingSpinner,
  },
  [LIGHT_THEME]: {},
  [DARK_THEME]: {},
}

theme[DARK_THEME] = {
  colors: {
    primary: '#000',
    primaryText: '#fff',
    secondary: '#fff',
    secondaryText: '#000',
    progressBarBackground: '#222325',
    progressBarForeground: '#fff',
  },
  icons: {
    enterFullscreen: fullscreenIcon,
    exitFullscreen: backIcon,
    rewind: rewindIcon,
    play: playIcon,
    pause: pauseIcon,
    forward: forwardIcon,
  },
  assets: {
    ...theme.assets,
    activePlaylistItem: ActiveItemLight,
  },
}

theme[LIGHT_THEME] = {
  colors: {
    primary: '#000',
    primaryText: '#fff',
    secondary: '#fff',
    secondaryText: '#000',
    progressBarBackground: '#000',
    progressBarForeground: '#fff',
  },
  icons: {
    enterFullscreen: fullscreenIcon,
    exitFullscreen: backIcon,
    rewind: rewindIcon,
    play: playIcon,
    pause: pauseIcon,
    forward: forwardIcon,
  },
  assets: {
    ...theme.assets,
    activePlaylistItem: ActiveItemLight,
  },
}

export default theme

interface Theme {
  mode: 'LIGHT_THEME' | 'DARK_THEME'
}

export const defaultChannelTheme: Theme = {
  mode: DARK_THEME,
}
