import {
  CONSENT_COOKIE_NAME,
  getConsentIntention,
  INTENTION_ADVERTISING,
  INTENTION_TRACKING,
  LATEST_VERSION,
} from '@cbc/hbbtv-consent-cookie-utility'
import Cookies, { Cookie, CookieSetOptions } from 'universal-cookie'
import { bugsnag } from '@lib/bugsnag'

const cookies = new Cookies()

const getFromLocalStorage = (name: string, parseValue: boolean) => {
  try {
    if (localStorage) {
      const itemFromLocalStorage = localStorage.getItem(name)

      if (itemFromLocalStorage !== null) {
        return parseValue
          ? JSON.parse(itemFromLocalStorage)
          : itemFromLocalStorage
      }
    }
  } catch (e) {
    /* istanbul ignore next */
    bugsnag?.notify(e)
  }
}

export function getConsent(parseValue = true) {
  const value = cookies.get(CONSENT_COOKIE_NAME, { doNotParse: !parseValue })

  if (!value) {
    return getFromLocalStorage(CONSENT_COOKIE_NAME, parseValue)
  }

  return value
}

/**
 * @param {intention} intention
 * @param {string} defaultFromEnv
 */
function getIntention(intention: string, defaultFromEnv = 'false') {
  return (
    getConsentIntention(getConsent(false), intention) ??
    // Env vars are strings
    defaultFromEnv?.toLowerCase() === 'true'
  )
}

export function isLatestVersion() {
  const consentObject = getConsent()
  if (typeof consentObject?.version !== 'undefined') {
    return consentObject.version === LATEST_VERSION
  }
  return false
}

export function hasTrackingConsent() {
  return getIntention(
    INTENTION_TRACKING,
    process.env.NEXT_PUBLIC_DEFAULT_VALUE_CONSENT_TRACKING,
  )
}

export function hasAdvertisingConsent() {
  return getIntention(
    INTENTION_ADVERTISING,
    process.env.NEXT_PUBLIC_DEFAULT_VALUE_CONSENT_ADVERTISING,
  )
}

export function getConsentLastUpdateDate() {
  const consentObject = getConsent()
  if (consentObject?.lastUpdate) {
    return new Date(consentObject.lastUpdate)
  }
}

const getExpireTime = (expiresInDays?: number) => {
  if (!expiresInDays) {
    /* istanbul ignore next */
    return new Date()
  }

  const today = new Date()
  today.setTime(today.getTime())

  if (expiresInDays) {
    expiresInDays = expiresInDays * 1000 * 60 * 60 * 24
  }

  return new Date(today.getTime() + expiresInDays)
}

const getLastUpdate = () => {
  const date = new Date()
  return date.getTime()
}

const setCookie = (name: string, value: Cookie, options: CookieSetOptions) => {
  try {
    cookies.set(name, value, options)
  } catch (e) {
    /* istanbul ignore next */
    bugsnag?.notify(e)
  }
}

export const setLocalStorage = (name: string, value: any) => {
  try {
    if (localStorage) {
      localStorage.setItem(name, JSON.stringify(value))
    }
  } catch (e) {
    /* istanbul ignore next */
    bugsnag?.notify(e)
  }
}

export function setConsent(value: any) {
  const consent = {
    intentions: value,
    lastUpdate: getLastUpdate(),
  }

  const expires = getExpireTime(365)

  setCookie(CONSENT_COOKIE_NAME, consent, {
    expires,
    domain: process.env.NEXT_PUBLIC_CONSENT_COOKIE_DOMAIN,
    secure: false,
  })
  setLocalStorage(CONSENT_COOKIE_NAME, consent)

  return consent
}
