// @ts-ignore
import { encodeToQueryString } from '@cbc/hbbtv-referrer-app-utility'
import getConfig from 'next/config'
import { BroadcastInfo } from './utils/currentStation'
import { stringifyUrl } from './utils/url/stringifyUrl'
const { publicRuntimeConfig } = getConfig()

const LAUNCHBAR_APP_LABEL = 'launchbar'
const DATA_PRIVACY_CENTER_APP_LABEL = 'dsc'

type AppName = typeof LAUNCHBAR_APP_LABEL | typeof DATA_PRIVACY_CENTER_APP_LABEL

type StationUrlsByCountry = {
  [country: string]: string
}

type StationAitEntry = {
  dvb: StationUrlsByCountry
  http: StationUrlsByCountry
}

type AitConfiguration = {
  [station: string]: StationAitEntry
}

const app2EnvVarMapping = {
  [LAUNCHBAR_APP_LABEL]:
    publicRuntimeConfig.processEnv.NEXT_PUBLIC_AIT_URLS_LAUNCHBAR,
  [DATA_PRIVACY_CENTER_APP_LABEL]:
    publicRuntimeConfig.processEnv.NEXT_PUBLIC_AIT_URLS_DSC,
}

function getAITURLs(
  app: AppName,
  station: string,
  country: string,
): { dvb: string; http: string } {
  // @ts-ignore
  const parsedConfig: AitConfiguration = JSON.parse(app2EnvVarMapping[app])

  return {
    dvb: parsedConfig[station].dvb[country],
    http: parsedConfig[station].http[country],
  }
}

function currentAppInfo(): string {
  const appInfo = {
    'TV Highlights Version':
      publicRuntimeConfig.processEnv.NEXT_PUBLIC_APP_VERSION ?? 'dev',
    'Release Stage': publicRuntimeConfig.processEnv.NEXT_PUBLIC_ENV ?? 'local',
  }

  return encodeURIComponent(JSON.stringify(appInfo))
}

function currentReferrerApp(): string {
  // TvHv4 does not use dvb aits. Deeplinking to any page is possible, so we can always use the current href as the return url.
  return encodeToQueryString({
    httpUrl: window.location.href,
    dvbUrl: '',
  })
}

function openApplicationByLabel(
  label: AppName,
  query: { [key: string]: any } = {},
  broadcastInfo: Required<BroadcastInfo>,
) {
  const { dvb, http } = getAITURLs(
    label,
    broadcastInfo.station,
    broadcastInfo.country,
  )

  window.oipfApp.openApplication(
    stringifyUrl(dvb, query),
    stringifyUrl(http, query),
  )
}

export function openLaunchbar(
  broadcastInfo: BroadcastInfo,
  query: { [key: string]: any } = {},
) {
  if (!broadcastInfo.station || !broadcastInfo.country) {
    return
  }
  openApplicationByLabel(
    LAUNCHBAR_APP_LABEL,
    query,
    broadcastInfo as Required<BroadcastInfo>,
  )
}

export function openDataPrivacyCenter(
  broadcastInfo: BroadcastInfo,
  query: { [key: string]: any } = {},
) {
  if (!broadcastInfo.station || !broadcastInfo.country) {
    return
  }
  openApplicationByLabel(
    DATA_PRIVACY_CENTER_APP_LABEL,
    {
      ...query,
      appinfo: currentAppInfo(),
      referrerApp: currentReferrerApp(),
    },
    broadcastInfo as Required<BroadcastInfo>,
  )
}
