import { PropsWithChildren, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

export function WaitForRouter({ children }: PropsWithChildren): JSX.Element {
  const router = useRouter()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setReady(true)
  }, [])

  // `ready` check is necessary for empty query cases where
  // !router.isReady on BE and
  // router.isReady immediately on FE
  if (ready && router.isReady) {
    return <>{children}</>
  }

  return <></>
}

export default WaitForRouter
